import React, { useEffect } from 'react';
import './App.css';
import {connect} from "react-redux";
import {Router, Route, Switch} from 'react-router-dom'
import history from "./history";
import Layout from "./client/layout/Layout";
import Login from './client/components/login/Login'
import Admin from './client/components/admin/Admin'
import TextById from "./client/components/textById/TextById";
import Settings from "./client/components/settings/Settings"
import Contacts from "./client/components/contacts/Contacts"
import {HTTP} from "./axios";

const App = (props) => {
  // как очищать через какое то время токен/ как передвать токен в запросах

  const token = localStorage.getItem('token');
  HTTP.defaults.headers.common['token'] = token;

  useEffect(() => {

    if (token !== null) {
        HTTP({
          url: 'checkUserIsLogin',
          method: 'GET'
        })
          .then((data) => {
            if (data.data.isLogin) {
              props.isLogin({login: true, token: token});
            } else {
              HTTP({
                url: 'logOut',
                method: 'POST',
                data: {token: token}
              })
                .then(() => {
                  localStorage.removeItem('token');
                  history.push('/login');
                  props.isLogin({login: false, token: null});
                })
                .catch((err) => console.log(err))
            }
          })
          .catch((err) => console.log(err));

    } else {
      HTTP.defaults.headers.common['token'] = null;
      props.isLogin({login: false, token: null});
      history.push('/login')
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Router history={history}>
      <div className="App">
        <Layout>
          <Switch>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/' component={Admin}/>
            <Route path='/text/:id/:type' component={TextById}/>
            <Route path='/settings' component={Settings}/>
            <Route path='/contacts' component={Contacts}/>
            <Route path='*' component={Admin}/>
          </Switch>
        </Layout>
      </div>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteText: (id) => dispatch({
      type: 'deleteText',
      payload: id
    }),
    isLogin: (loginData) => dispatch({
      type: 'isLogin',
      payload: loginData
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

