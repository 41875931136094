const initionState = {
  type: '',
  show: false
};

export default function actionDialog(state = initionState, action) {
  switch (action.type) {
    case 'showActionDialog':
      return {
        type: action.payload,
        show: true
      };
    case 'hideActionDialog':
      return {
        type: '',
        show: false
      };
    default:
      return state;
  }
}
