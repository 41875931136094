import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import {Close} from "@material-ui/icons";
import {HTTP} from "../../../axios";



const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-around'
  },
  title: {
    marginBottom: theme.spacing(5)
  },
  item: {
    marginBottom: theme.spacing(3)
  },
  contactItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  addContact: {
    color: 'green',
    fontSize: '25px'
  },
  trash: {
    color: 'red',
    fontSize: '25px'
  },
}));

const Contacts = (props) => {

  document.title = 'SYNTHESIS | contacts';

  const [contacts, contactsSet] = useState();


  const contactsSave = (ev) => {
    ev.preventDefault();

    const items = ev.target.querySelectorAll('[label]');
    const contentDesktop = contacts.contentDesktop;
    const contentMobile = contacts.contentMobile;
    const itemsValue = {};

    items.forEach((el) => {
      itemsValue[el.attributes.label.value] = el.children[0].value;
    });

    contentDesktop.forEach((el) => {
      if (el.title.trim() === '' && el.label.trim() === '' && el.href.trim() === '') {
        contentDesktop.splice(contentDesktop.indexOf(el), 1);
      }
    });

    contentMobile.forEach((el) => {
      if (el.title.trim() === '' && el.label.trim() === '' && el.href.trim() === '') {
        contentMobile.splice(contentMobile.indexOf(el), 1);
      }
    });

    itemsValue.contentDesktop = contentDesktop;
    itemsValue.contentMobile = contentMobile;


    function request() {
      HTTP({
        url: 'editContacts',
        method: 'POST',
        data: itemsValue
      })
        .then(() => {
          props.showActionDialog('success')
        })
        .catch(() => props.showActionDialog('error'))
    }
    request();

  };

  const addContact = (ev) => {
    ev.preventDefault();

    const items = ev.target.querySelectorAll('[label]');
    const itemsValue = {};

    items.forEach((el) => {
      itemsValue[el.attributes.label.value] = el.children[0].value;
    });

    itemsValue.id = new Date().getTime().toString(20) + Math.floor(Math.random() * 1000).toString(20);

    const contentDesktop = [...contacts.contentDesktop];
    const contentMobile = [...contacts.contentMobile];

    if (ev.target.id === 'contactsDesktop') {
      contentDesktop.push(itemsValue);
      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: contentDesktop,
        contentMobile: contacts.contentMobile
      });
    } else {
      contentMobile.push(itemsValue);
      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: contacts.contentDesktop,
        contentMobile: contentMobile
      });
    }

    items.forEach((el) => {
      el.children[0].value = '';
    });
  };

  const editOneContact = (ev, id, type) => {
    const value = ev.target.value;
    const label = ev.target.parentNode.getAttribute('label');

    if (type === 'desktop') {
      const content = [...contacts.contentDesktop];

      content.forEach((el) => {
        if (el.id === id) {
          content[content.indexOf(el)][label] = value;
        }
      });

      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: content,
        contentMobile: contacts.contentMobile
      });
    }
    else {
      const content = [...contacts.contentMobile];

      content.forEach((el) => {
        if (el.id === id) {
          content[content.indexOf(el)][label] = value;
        }
      });

      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: contacts.contentDesktop,
        contentMobile: content
      });
    }
  };

  const deleteContact = (id, type) => {
    if (type === 'desktop') {
      const content = [...contacts.contentDesktop];

      content.forEach((el) => {
        if (el.id === id) {
          content.splice(content.indexOf(el), 1)
        }
      });

      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: content,
        contentMobile: contacts.contentMobile
      });
    } else {
      const content = [...contacts.contentMobile];

      content.forEach((el) => {
        if (el.id === id) {
          content.splice(content.indexOf(el), 1)
        }
      });

      contactsSet({
        title: contacts.title,
        description: contacts.description,
        contentDesktop: contacts.contentDesktop,
        contentMobile: content
      });
    }
  };


  useEffect(() => {

    function request() {
      HTTP({
        url: 'getContacts',
        method: 'GET'
      })
        .then((data) => {
          contactsSet(data.data[0]);
        })
        .catch((err) => console.log(err))
    }
    request();
// eslint-disable-next-line
  }, []);

  const classes = useStyles();

  if (!contacts) return null;

  return (
    <React.Fragment>

      <Paper className={classes.paper}>
        <Grid item xs={10}>

          <Typography variant="h6" component="h4" className={classes.title}>
            Desktop contacts
            <Typography variant="caption" display="block" gutterBottom>
              It is the contacts will be displayed on the desktop version
            </Typography>
          </Typography>

          {contacts.contentDesktop.map((contact) => {
            return (
              <div className={classes.contactItem} key={contact.id}>
                <FormControl>
                  <InputLabel>Contact title</InputLabel>
                  <Input label='title' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'desktop')
                  }} defaultValue={contact.title}/>
                </FormControl>

                <FormControl>
                  <InputLabel>Contact label</InputLabel>
                  <Input label='label' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'desktop')
                  }} defaultValue={contact.label}/>
                </FormControl>

                <FormControl>
                  <InputLabel>Contact href</InputLabel>
                  <Input label='href' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'desktop')
                  }} defaultValue={contact.href}/>
                </FormControl>

                <IconButton onClick={() => {
                  deleteContact(contact.id, 'desktop')
                }}>
                  <Close className={classes.trash}/>
                </IconButton>
              </div>
            )
          })}

          <form id='contactsDesktop' className={classes.contactItem} onSubmit={(ev) => {
            addContact(ev)
          }}>
            <FormControl required>
              <InputLabel>Contact title</InputLabel>
              <Input label='title'/>
            </FormControl>

            <FormControl required>
              <InputLabel>Contact label</InputLabel>
              <Input label='label'/>
            </FormControl>

            <FormControl required>
              <InputLabel>Contact href</InputLabel>
              <Input label='href'/>
            </FormControl>

            <IconButton type='submit'>
              <AddIcon className={classes.addContact}/>
            </IconButton>
          </form>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid item xs={10}>

          <Typography variant="h6" component="h4" className={classes.title}>
            Mobile contacts
            <Typography variant="caption" display="block" gutterBottom>
              It is the contacts will be displayed on the mobile version
            </Typography>
          </Typography>

          {contacts.contentMobile.map((contact) => {
            return (
              <div className={classes.contactItem} key={contact.id}>
                <FormControl>
                  <InputLabel>Contact title</InputLabel>
                  <Input label='title' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'mobile')
                  }} defaultValue={contact.title}/>
                </FormControl>

                <FormControl>
                  <InputLabel>Contact label</InputLabel>
                  <Input label='label' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'mobile')
                  }} defaultValue={contact.label}/>
                </FormControl>

                <FormControl>
                  <InputLabel>Contact href</InputLabel>
                  <Input label='href' onBlur={(ev) => {
                    editOneContact(ev, contact.id, 'mobile')
                  }} defaultValue={contact.href}/>
                </FormControl>

                <IconButton onClick={() => {
                  deleteContact(contact.id, 'mobile')
                }}>
                  <Close className={classes.trash}/>
                </IconButton>
              </div>
            )
          })}

          <form id='contactsMobile' className={classes.contactItem} onSubmit={(ev) => {
            addContact(ev)
          }}>
            <FormControl required>
              <InputLabel>Contact title</InputLabel>
              <Input label='title'/>
            </FormControl>

            <FormControl required>
              <InputLabel>Contact label</InputLabel>
              <Input label='label'/>
            </FormControl>

            <FormControl required>
              <InputLabel>Contact href</InputLabel>
              <Input label='href'/>
            </FormControl>

            <IconButton type='submit'>
              <AddIcon className={classes.addContact}/>
            </IconButton>
          </form>
        </Grid>
      </Paper>

      <form onSubmit={(ev) => {
        contactsSave(ev)
      }}>
        <Paper className={classes.paper}>
          <Grid item xs={10}>

            <Typography variant="h6" component="h4" className={classes.title}>
              Contacts seo options
              <Typography variant="caption" display="block" gutterBottom>
                It is the seo options of contacts page
              </Typography>
            </Typography>

            <FormControl fullWidth className={classes.item} required>
              <InputLabel>Contacts title</InputLabel>
              <Input label='title' defaultValue={contacts.title}/>
            </FormControl>

            <FormControl fullWidth className={classes.item} required>
              <InputLabel>Contacts description</InputLabel>
              <Input label='description' defaultValue={contacts.description}/>
            </FormControl>
          </Grid>
        </Paper>

        <Button
          className={classes.item}
          variant="contained"
          color="primary"
          size='large'
          type='submit'
        >
          Save
        </Button>

      </form>

    </React.Fragment>
  )
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    showActionDialog: (type) => dispatch({
      type: 'showActionDialog',
      payload: type
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
