import React, {useState, useEffect} from 'react';
import './textById.css'
import Grid                         from "@material-ui/core/Grid";
import FormControl                  from "@material-ui/core/FormControl";
import InputLabel                   from "@material-ui/core/InputLabel";
import Input                        from "@material-ui/core/Input";
import Button                       from "@material-ui/core/Button";
import history                      from "../../../history";
import {connect}                    from "react-redux";
import makeStyles                   from "@material-ui/core/styles/makeStyles";
import {Paper}                      from "@material-ui/core";
import Typography                   from "@material-ui/core/Typography";
import {HTTP}                       from "../../../axios";
import TextFieldsIcon               from '@material-ui/icons/TextFields';
import FindInPageIcon               from '@material-ui/icons/FindInPage';
import Select                       from '@material-ui/core/Select';
import MenuItem                     from '@material-ui/core/MenuItem';
import IconButton                   from "@material-ui/core/IconButton";
import AddIcon                      from "@material-ui/icons/Add";
import ClickAwayListener            from '@material-ui/core/ClickAwayListener';

// скрывать при даблклике / расположение элемента подсказки и списка текстов

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(5)
  },
  item: {
    marginBottom: theme.spacing(3)
  },
  button: {
    marginBottom: theme.spacing(3)
  },
  select: {
    marginTop: theme.spacing(3),
    width: "50%"
  },
  addLink: {
    color: 'white',
    fontSize: '20px',
  },
  addLinkWrapper: {
    padding: '0 10px 10px 10px',
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

const TextById = (props) => {
  const [textState, textSet] = useState(null);
  const [fontSizeState, fontSizeSet] = useState({
    fontSize: "text--45"
  });
  const [selectionState, selectionSet] = useState(null);
  const id = props.match.params.id;
  const type = props.match.params.type;

  // добавление события вставки
  const addListenerContent = (ev) => {
    ev.preventDefault();
    // get text representation of clipboard
    const text = (ev.originalEvent || ev).clipboardData.getData('text/plain');
    // insert text manually
    document.execCommand("insertHTML", false, text);
  };


  // событие при нажатии enter
  const addListenerNewLine = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      document.execCommand('insertHTML', false, '<br>');
      return false;
    }
  };


  const addListenerAll = () => {
    const allSpans = document.querySelectorAll('[data-id]');
    const content = document.getElementById(id + 'content');

    // добавление контенту
    content.addEventListener('paste', (ev) => {
      addListenerContent(ev)
    });

    content.addEventListener('keydown', (ev) => {
      addListenerNewLine(ev)
    });

    // добавление спанам

    allSpans.forEach((el) => {
      el.addEventListener('click', (ev) => {
        showInfo(ev)
      });
      el.addEventListener('mouseleave', (ev) => {
        hideInfo(ev)
      });

      el.children[0].addEventListener('click', (ev) => {
        destroySpan(ev)
      });
    })

  };

  // получение по id текста из базы если это режим редактирования
  useEffect(() => {
    if (type === 'edit') {
      HTTP({
        url: 'getAllTexts',
        method: 'GET'
      })
        .then((data) => {

          data.data.forEach((text) => {
            if (text.id === id) {
              textSet([text]);
              fontSizeSet({
                ...fontSizeState,
                fontSize: text.fontSize
              });
            }
          });

          props.getAllTexts(data.data);
        })
        .catch((err) => console.log(err))

    } else {
      const content = document.getElementById(id + 'content');

      // добавление контенту
      content.addEventListener('paste', (ev) => {
        addListenerContent(ev)
      });

      content.addEventListener('keydown', (ev) => {
        addListenerNewLine(ev)
      });
    }
    // eslint-disable-next-line
  }, [type, id]);


  useEffect(() => {
    if (props.allTexts.length !== 0 && textState !== null) {
      addListenerAll()
    }
    // eslint-disable-next-line
  }, [textState, props.allTexts.length]);


  const backend = () => {
    const contentBack = document.getElementsByClassName('content')[0].cloneNode(true);
    contentBack.querySelectorAll('[data-id]').forEach((el) => {
      el.removeAttribute('contentEditable');
      el.children[0].remove();
    });
    return contentBack.innerHTML;
  };

  const createText = (ev) => {
    ev.preventDefault();
    const title = document.getElementById(id + 'title').value;
    const content = document.getElementById(id + 'content');
    const contentFront = content.innerHTML;
    const contentText = content.innerText;
    const fontSize = fontSizeState.fontSize;
    const slug = document.getElementById(id + 'slug').value;
    const description = document.getElementById(id + 'description').value;
    const sort = props.allTexts.length + 1;

    let randomId = new Date().getTime().toString(20) + Math.floor(Math.random() * 1000).toString(20);

    function request() {
      HTTP({
        url: 'createText',
        method: 'POST',
        data: {
          id: randomId,
          title,
          slug,
          sort,
          fontSize,
          description,
          contentText,
          contentFront,
          contentBack: backend()
        }
      })
        .then(
          () => {
            props.addText({
              id: randomId,
              title,
              slug,
              sort,
              fontSize,
              description,
              contentText,
              contentFront,
              contentBack: backend()
            });
            history.push('/')
          })
        .catch((err) => console.log(err))
    }

    request();

  };

  const textEdit = (ev) => {
    ev.preventDefault();
    const title = document.getElementById(id + 'title').value;
    const content = document.getElementById(id + 'content');
    const fontSize = fontSizeState.fontSize;
    const slug = document.getElementById(id + 'slug').value;
    const sort = props.allTexts.find(text => text.id === id).sort;
    const description = document.getElementById(id + 'description').value;
    const contentFront = content.innerHTML;
    const contentText = content.innerText;


    function request() {
      HTTP({
        url: 'editText',
        method: 'POST',
        data: {
          id,
          title,
          slug,
          sort,
          fontSize,
          description,
          contentText,
          contentFront,
          contentBack: backend()
        }
      })
        .then(() => {
          props.editText({
            id,
            title,
            slug,
            sort,
            fontSize,
            description,
            contentText,
            contentFront,
            contentBack: backend()
          });
          props.showActionDialog('success')
        })
        .catch(() => props.showActionDialog('error'))
    }

    request();

  };

  const destroySpan = (ev) => {
    const parent = ev.target.parentNode;
    parent.children[0].remove();
    parent.outerHTML = parent.innerHTML;

    hideInfo();
  };

  const buildSpan = (id) => {
    window.getSelection().setBaseAndExtent(selectionState.anchorNode, selectionState.anchorOffset, selectionState.focusNode, selectionState.focusOffset);

    const sel = window.getSelection();

    if (sel.toString().trim() !== '') {
      try {
        const span = document.createElement('span');
        span.setAttribute('data-id', id);
        span.setAttribute('contentEditable', 'false');

        span.addEventListener('click', (ev) => {
          showInfo(ev)
        });

        span.addEventListener('mouseleave', (ev) => {
          hideInfo(ev)
        });

        const range = sel.getRangeAt(0).cloneRange();
        range.surroundContents(span);
        sel.addRange(range);

        // создание и добавление кнопки удаления
        const deleteSpan = document.createElement('span');
        deleteSpan.classList.add('close');
        deleteSpan.addEventListener('click', (ev) => {
          destroySpan(ev)
        });
        span.appendChild(deleteSpan);
        // создание и добавление кнопки удаления

        switchDialogTextsList(false)

      } catch (e) {
        console.warn(e);
      }
    }
  };

  const selected = (ev) => {
    const sel = window.getSelection();

    if (sel.baseNode === sel.extentNode && ev.target.className === 'content' && sel.toString().trim().length !== 0) {

      // отображение списка текстов
      const dialogTextsList = document.getElementsByClassName('textsListWrapper')[0];

      // maaaaario
      if (sel.toString() === 'mario') {
        setTimeout(() => {
          props.showActionDialog('mario')
        }, 150);
        return;
      }
      // maaaaario

      const range = sel.getRangeAt(0).cloneRange();

      let coords = range.getBoundingClientRect();

      const scroll = window.pageYOffset;

      const left = coords.left + coords.width / 2;

      dialogTextsList.style.left = left - 25 + 'px';
      dialogTextsList.style.top = coords.top + scroll + 35 + 'px';

      selectionSet({
        anchorNode: sel.anchorNode,
        anchorOffset: sel.anchorOffset,
        focusNode: sel.focusNode,
        focusOffset: sel.focusOffset
      });

      setTimeout(() => switchDialogTextsList(true), 0)

      // отображение списка текстов
    } else {
      switchDialogTextsList(false)
    }
  };

  const switchDialogTextsList = (type) => {
    const dialogTextsList = document.getElementsByClassName('textsListWrapper')[0];
    if (!type) {
      dialogTextsList.style.display = 'none';
    } else {
      dialogTextsList.style.display = 'block';
    }
  };


  // ВСПЛЫВАЮЩАЯ ПОДСКАЗКА
  const showInfo = (ev) => {
    ev.preventDefault();

    const tooltip = document.querySelectorAll('[label=tooltip]')[0];

    const id = ev.target.getAttribute('data-id');

    if (!id) return;

    const prefix = id.split(":");

    let coords = ev.target.getBoundingClientRect();

    const left = coords.left + coords.width / 2;

    tooltip.style.left = left - 25 + 'px';
    tooltip.style.top = coords.top + 45 + 'px';


    switch (prefix[0]) {
      case 'contacts':
        tooltip.textContent = 'Link to page "Contacts"';
        tooltip.style.display = 'block';
        break;
      case "link":
        tooltip.textContent = `Link to resource "${prefix[1]}"`;
        tooltip.style.display = 'block';
        break;
      case "justColor":
        tooltip.textContent = "Just the highlighted word";
        tooltip.style.display = 'block';
        break;
      case "text":
        props.allTexts.forEach((text) => {
          if (`text:${text.id}` === id) {
            tooltip.textContent = `Link to text "${text.title}"`;

            tooltip.style.display = 'block';
          }
        });
        break;
    }
  };

  const hideInfo = () => {
    const tooltip = document.querySelectorAll('[label=tooltip]')[0];

    tooltip.style.display = 'none';
  };
  // ВСПЛЫВАЮЩАЯ ПОДСКАЗКА

  const classes = useStyles();

  const TextsListDialog = () => {
    return (
      <ClickAwayListener onClickAway={() => switchDialogTextsList(false)}>
        <ul className='dialogTextsList'>
          {props.allTexts.length === 0 ?
            <li style={{color: 'white', padding: '10px'}}>Don't have texts</li>
            :
            props.allTexts.map((text) => {
              if (text.id !== id) {
                return (
                  <li
                    id={text.id}
                    onMouseDown={(ev) => {
                      buildSpan(`text:${ev.currentTarget.id}`)
                    }}
                    className='dialogTextsItem'
                    key={text.id}>
                    <div className='linkWrapper'>
                      <div className='textIcon'>
                        <TextFieldsIcon/>
                      </div>
                      <span>{text.title}</span>
                    </div>
                  </li>
                )
              }
              return null;
            })}
          <li
            id='contacts'
            onMouseDown={(ev) => {
              buildSpan(`contacts:${ev.currentTarget.id}`)
            }}
            className='dialogTextsItem'
          >
            <div className='linkWrapper'>
              <div className='textIcon'>
                <FindInPageIcon/>
              </div>
              <span>Contacts</span>
            </div>
          </li>

          <li
            id='justColor'
            onMouseDown={(ev) => {
              buildSpan(`justColor:${ev.currentTarget.id}`)
            }}
            className='dialogTextsItem'
          >
            <div className='linkWrapper'>
              <div className='textIcon'>
                <FindInPageIcon/>
              </div>
              <span>Just highlight</span>
            </div>
          </li>

          <li id='resourceLink'>
            <div className={classes.addLinkWrapper}>
              <Input onFocus={ev => ev.preventDefault()} id="resourceLinkInput" className='linkToResource'
                     placeholder="Enter link"/>
              <IconButton onClick={() => {
                const inputValue = document.getElementById("resourceLinkInput").value;
                if (!inputValue.trim()) return;
                buildSpan(`link:${inputValue}`)
              }}>
                <AddIcon className={classes.addLink}/>
              </IconButton>
            </div>
          </li>
        </ul>
      </ClickAwayListener>
    )
  };

  const SelectComponent = () => (
    <FormControl className={classes.select}>
      <Select
        value={fontSizeState.fontSize}
        onChange={(ev) => fontSizeSet({
          fontSize: ev.target.value
        })}
      >
        <MenuItem value={"text--45"} style={{fontSize: "45px", fontWeight: "100"}}>45 synthesis is awesome</MenuItem>
        <MenuItem value={"text--40"} style={{fontSize: "40px", fontWeight: "100"}}>40 synthesis is awesome</MenuItem>
        <MenuItem value={"text--35"} style={{fontSize: "35px", fontWeight: "100"}}>35 synthesis is awesome</MenuItem>
      </Select>
      <Typography variant="caption" display="block" gutterBottom>
        Select line spacing, default 45
      </Typography>
    </FormControl>
  );

  switch (type) {
    case 'edit':
      if (!textState) return null;
      document.title = 'SYNTHESIS | edit text';
      return (
        <React.Fragment>
          <div label='tooltip' className='tooltip'/>
          <form onSubmit={(ev) => {
            textEdit(ev)
          }}>
            <Paper className={classes.paper}>
              <Grid item xs={10}>
                <Typography variant="h6" component="h4" className={classes.title}>
                  Main text options
                  <Typography variant="caption" display="block" gutterBottom>
                    It is the title and description you'll see on the page of text
                  </Typography>
                </Typography>
                <FormControl className='title' fullWidth required>
                  <InputLabel>Title</InputLabel>
                  <Input id={`${id}title`} defaultValue={textState[0].title}/>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Description</InputLabel>
                  <div
                    id={id + 'content'}
                    onMouseUp={(ev) => {
                      selected(ev)
                    }}
                    className='content'
                    contentEditable
                    dangerouslySetInnerHTML={{__html: textState[0].contentFront}}
                  >
                  </div>
                  <Typography variant="caption" display="block" gutterBottom>
                    To see dependency of word just click on it
                  </Typography>
                </FormControl>
                <SelectComponent/>
                <div className="textsListWrapper">
                  <TextsListDialog/>
                </div>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Grid item xs={10}>
                <Typography variant="h6" component="h4" className={classes.title}>
                  Text seo options
                  <Typography variant="caption" display="block" gutterBottom>
                    It is the seo options of text page
                  </Typography>
                </Typography>
                <FormControl fullWidth className={classes.item}>
                  <InputLabel>Slug</InputLabel>
                  <Input id={id + 'slug'} defaultValue={textState[0].slug}/>
                </FormControl>
                <FormControl fullWidth className={classes.item}>
                  <InputLabel>Description</InputLabel>
                  <Input id={id + 'description'} defaultValue={textState[0].description}/>
                </FormControl>
              </Grid>
            </Paper>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size='large'
              type='submit'
            >
              Save
            </Button>
          </form>
        </React.Fragment>
      );

    case 'create':
      document.title = 'SYNTHESIS | create text';
      return (
        <React.Fragment>
          <div label='tooltip' className='tooltip'/>
          <form onSubmit={(ev) => {
            createText(ev)
          }
          }>
            <Paper className={classes.paper}>
              <Grid item xs={10}>
                <Typography variant="h6" component="h4" className={classes.title}>
                  Create new text
                  <Typography variant="caption" display="block" gutterBottom>
                    It is the title and description you'll see on the page of text
                  </Typography>
                </Typography>
                <FormControl className='title' fullWidth required>
                  <InputLabel>Title</InputLabel>
                  <Input id={`${id}title`}/>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Description</InputLabel>
                  <div
                    id={id + 'content'}
                    onMouseUp={(ev) => {
                      selected(ev)
                    }}
                    className='content'
                    contentEditable
                    placeholder='input your text'
                  >
                  </div>
                  <Typography variant="caption" display="block" gutterBottom>
                    Forgot? ha-ha just click!
                  </Typography>
                </FormControl>
                <SelectComponent/>
                <div className="textsListWrapper">
                  <TextsListDialog/>
                </div>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Grid item xs={10}>
                <Typography variant="h6" component="h4" className={classes.title}>
                  Text seo options
                  <Typography variant="caption" display="block" gutterBottom>
                    It is the seo options of text page
                  </Typography>
                </Typography>
                <FormControl fullWidth className={classes.item}>
                  <InputLabel>Slug</InputLabel>
                  <Input id={id + 'slug'}/>
                </FormControl>
                <FormControl fullWidth className={classes.item}>
                  <InputLabel>Description</InputLabel>
                  <Input id={id + 'description'}/>
                </FormControl>
              </Grid>
            </Paper>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size='large'
              type='submit'
            >
              Create
            </Button>
          </form>
        </React.Fragment>
      );
    default:
      history.push('/');
      return null;
  }
};

function mapStateToProps(state) {
  return {
    allTexts: state.texts.texts
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllTexts: (texts) => dispatch({
      type: 'getAllTexts',
      payload: texts
    }),
    addText: (text) => dispatch({
      type: 'addText',
      payload: text
    }),
    editText: (text) => dispatch({
      type: 'editText',
      payload: text
    }),
    showActionDialog: (type) => dispatch({
      type: 'showActionDialog',
      payload: type
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextById);
