import React, {useEffect}                      from 'react';
import {Link}                                  from 'react-router-dom'
import Fab                                     from '@material-ui/core/Fab';
import AddIcon                                 from '@material-ui/icons/Add';
import ListItem                                from "@material-ui/core/ListItem";
import List                                    from "@material-ui/core/List";
import TextFields                              from '@material-ui/icons/TextFields';
import Grid                                    from "@material-ui/core/Grid";
import history                                 from "../../../history";
import {connect}                               from "react-redux";
import {makeStyles}                            from "@material-ui/core";
import Avatar                                  from "@material-ui/core/Avatar";
import ListItemAvatar                          from "@material-ui/core/ListItemAvatar";
import ListItemText                            from "@material-ui/core/ListItemText";
import Paper                                   from "@material-ui/core/Paper";
import {Close}                                 from "@material-ui/icons";
import IconButton                              from "@material-ui/core/IconButton";
import Slide                                   from "@material-ui/core/Slide";
import Dialog                                  from "@material-ui/core/Dialog";
import DialogTitle                             from "@material-ui/core/DialogTitle";
import DialogContentText                       from "@material-ui/core/DialogContentText";
import DialogContent                           from "@material-ui/core/DialogContent";
import DialogActions                           from "@material-ui/core/DialogActions";
import Button                                  from "@material-ui/core/Button";
import {HTTP}                                  from "../../../axios";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import context                                 from "react-router/modules/RouterContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  adminTitle: {
    marginRight: '15px',
    minWidth: '10vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  adminContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  listItem: {
    margin: '15px 0 15px 0'
  },
  addText: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  trash: {
    color: 'red',
    fontSize: '25px'
  },
  delete: {
    color: 'red'
  }
});

const Admin = (props) => {

  document.title = 'SYNTHESIS | all texts';

  const [deleteDialog, deleteDialogSet] = React.useState({
    show: false,
    id: null
  });


  useEffect(() => {
    // запрос на получение всех текстов из базы
    HTTP({
      url: 'getAllTexts',
      method: 'GET'
    })
      .then((data) => {
        props.getAllTexts(data.data);
      })
      .catch((err) => console.log(err))
    // eslint-disable-next-line
  }, []);

  const deleteDialogOpen = (ev, id) => {
    ev.preventDefault();
    deleteDialogSet({
      show: true,
      id: id
    });
  };

  const deleteDialogClose = () => {
    deleteDialogSet({
      show: false,
      id: null
    });
  };

  const goToCreateText = () => {
    let randomId = new Date().getTime().toString(20) + Math.floor(Math.random() * 1000).toString(20);
    history.push(`/text/${randomId}/create`)
  };

  const deleteText = (id) => {
    function request() {
      HTTP({
        url: `/deleteText/${id}`,
        method: 'DELETE'
      })
        .then(() => {
          props.showActionDialog('success');
          deleteDialogClose();
          props.deleteText({id: id});
        })
        .catch(() => props.showActionDialog('error'))
    }

    request();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((text, index) => {
      if (text.sort === index + 1) return;

      text.sort = index + 1;

      function request() {
        HTTP({
          url: 'editText',
          method: 'POST',
          data: text
        })
          .then(() => {
            props.showActionDialog('success')})
          .catch(() => props.showActionDialog('error'))
      }
      request();
    });
    props.getAllTexts(result);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    reorder(
      props.allTexts,
      result.source.index,
      result.destination.index
    );
    //
    // props.getAllTexts(texts);
  };


  const classes = useStyles();

  return (
    <Grid container
          direction="column"
    >
      <Grid item xs={12}>
        <List>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps}
                     ref={provided.innerRef}>
                  {props.allTexts.map((text, index) => {
                    const textPreview = text.contentText.length >= 50 ? `${text.contentText.substr(0, 50)} ...` : text.contentText;
                    return (
                      <Draggable key={text.id} draggableId={text.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <Paper className={classes.listItem}>
                              <Link to={`/text/${text.id}/edit`} style={{textDecoration: 'none', color: 'inherit'}}>
                                <ListItem button>
                                  <ListItemAvatar>
                                    <Avatar>
                                      <TextFields/>
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={text.title} secondary={textPreview}/>

                                  <IconButton onClick={(ev) => {
                                    deleteDialogOpen(ev, text.id)
                                  }}>
                                    <Close className={classes.trash}/>
                                  </IconButton>

                                </ListItem>
                              </Link>
                            </Paper>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>

        <Dialog
          open={deleteDialog.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={deleteDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Are u sure to delete that text"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If u do it all dependencies will be removed!¡
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              deleteText(deleteDialog.id)
            }} className={classes.delete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>

      <Grid item xs={12} className={classes.addText}>
        <Fab size="small" color="primary" aria-label="add" onClick={goToCreateText}>
          <AddIcon/>
        </Fab>
      </Grid>

    </Grid>
  )
};

function mapStateToProps(state) {
  return {
    allTexts: state.texts.texts
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllTexts: (texts) => dispatch({
      type: 'getAllTexts',
      payload: texts
    }),
    deleteText: (id) => dispatch({
      type: 'deleteText',
      payload: id
    }),
    showActionDialog: (type) => dispatch({
      type: 'showActionDialog',
      payload: type
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
