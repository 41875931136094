import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import history from "../../history";
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {AccountCircle, Brightness2, WbSunny} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Link} from "react-router-dom";
import {grey, indigo} from "@material-ui/core/colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import {connect} from "react-redux";
import ActionDialog from "../components/actionDialog/ActionDialog";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {HTTP} from "../../axios";
import Logo from './logo.png'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Layout = (props) => {

  const [themeState, themeSet] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [logOutDialog, logOutDialogSet] = React.useState(false);

  useEffect(() => {
    const theme = localStorage.getItem('theme');

    if (theme !== null && theme !== 'false') {
      themeSet(true)
    }

    switch (history.location.pathname) {
      case '/':
        return setTabValue(0);
      case '/contacts':
        return setTabValue(1);
      case '/settings':
        return setTabValue(2);
      default:
        return setTabValue(0);
    }
    // eslint-disable-next-line
  }, []);

  let theme;
  if (themeState) {
    theme = createMuiTheme({
      palette: {
        type: "dark",
        primary: {
          main: grey[900],
          light: grey[50]
        },
        secondary: {
          main: grey[700]
        }
      }
    })
  } else {
    theme = createMuiTheme({
      palette: {
        type: "light",
        primary: {
          main: indigo[900]
        },
        secondary: {
          main: grey[300]
        }
      }
    })
  }

  const useStyles = makeStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      color: 'white',
      fontFamily: 'Roboto',
      textDecoration: 'none',
      fontSize: '20px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    navigation: {
      display: 'flex',
      alignItems: 'center'
    },
    seo: {
      color: 'white',
      fontFamily: 'Roboto',
      textDecoration: 'none',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginRight: '20px'
    },
    main: {
      padding: '20px 20vw 20px 20vw',
      minHeight: 'calc(100vh - 152px)',
      backgroundColor: theme.palette.secondary.main
    },
    textArea: {
      color: theme.palette.text.primary,
      fontSize: '18px',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '20px',
      margin: '15px 0 15px 0',
      outline: 'none',
      border: 'none',
      backgroundColor: 'inherit',
      borderBottom: '1px solid grey'
    },
    layoutLogo: {
      height: "34px"
    }
  });



  // tabs in header
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        setTabValue(newValue);
        return history.push('/');
      case 1:
        setTabValue(newValue);
        return history.push('/contacts');
      case 2:
        setTabValue(newValue);
        return history.push('/settings');
      default:
        return history.push('/');
    }
  };
  // tabs in header


  const logOutDialogOpen = () => {
    logOutDialogSet(true);
  };

  const logOutDialogClose = () => {
    logOutDialogSet(false);
  };

  const logOut = () => {

    function request() {
      HTTP({
        url: 'logOut',
        method: 'POST',
        data: {token: props.login.token}
      })
        .then(() => {
          localStorage.removeItem('token');
          history.push('/login');
          props.isLogin({login: false, token: null});
        })
        .catch((err) => console.log(err))
    }
    request();

  };

  const saveTheme = () => {
    localStorage.setItem('theme', `${!themeState}`);
    themeSet(!themeState)
  };

  const classes = useStyles();

  return (
    <div className={themeState ? 'dark' : 'light'}>
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar className={classes.header}>
            <div>
              {props.login.login ?
                <Link to={'/'} onClick={() => {
                  setTabValue(0);
                }
                } className={classes.logo}>
                  <img className={classes.layoutLogo} src={Logo} alt="logo"/>
                </Link>
                :
                <Link to={'/login'} className={classes.logo}>
                  <img className={classes.layoutLogo} src={Logo} alt="logo"/>
                </Link>
              }
            </div>
            <div className={classes.navigation}>
              {(themeState)
                ?
                <IconButton onClick={saveTheme} style={{color: 'lightblue'}}>
                  <Brightness2/>
                </IconButton>
                :
                <IconButton onClick={saveTheme} style={{color: 'orange'}}>
                  <WbSunny/>
                </IconButton>
              }
              {!props.login.login ?
                <Link to={'/login'}>
                  <IconButton style={{color: 'white'}}>
                    <AccountCircle/>
                  </IconButton>
                </Link>
                :

                <React.Fragment>
                  <IconButton style={{color: 'white'}} onClick={logOutDialogOpen}>
                    <ExitToAppIcon/>
                  </IconButton>

                  <Dialog
                    open={logOutDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={logOutDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title">{"Are u sure u want to log out"}</DialogTitle>
                    <DialogActions>
                      <Button onClick={logOutDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={() => {
                        logOut();
                        logOutDialogClose();
                      }}>
                        Log out
                      </Button>
                    </DialogActions>
                  </Dialog>

                </React.Fragment>
              }
            </div>
          </Toolbar>
        </AppBar>

        {!props.login.login ? null :
          <Paper square>
            <Tabs value={tabValue} onChange={handleChange} centered indicatorColor="primary">
              <Tab label="All Texts" {...a11yProps(0)} />
              <Tab label="Contacts" {...a11yProps(1)} />
              <Tab label="Settings" {...a11yProps(2)} />
            </Tabs>
          </Paper>
        }

        <div className={classes.main}>
          <Typography component="div">
            {props.children}
          </Typography>
        </div>

        {props.actionDialog.show ?
          <ActionDialog type={props.actionDialog.type}/>
          :
          null
        }

      </ThemeProvider>
    </div>
  );
};



function mapStateToProps(state) {
  return {
    login: state.login,
    actionDialog: state.actionDialog
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllTexts: (texts) => dispatch({
      type: 'getAllTexts',
      payload: texts
    }),
    deleteText: (id) => dispatch({
      type: 'deleteText',
      payload: id
    }),
    isLogin: (loginData) => dispatch({
      type: 'isLogin',
      payload: loginData
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
