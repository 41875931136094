const initionState = {
    texts: []
};

export default function texts(state = initionState, action) {
    let cloneTexts = [...state.texts];
    switch (action.type) {
      case 'getAllTexts':
        cloneTexts = action.payload;
        return {
          texts: cloneTexts
        };
        case 'addText':
            const newText = {
                id: action.payload.id,
                title: action.payload.title,
                slug: action.payload.slug,
                description: action.payload.description,
                contentText: action.payload.contentText,
                contentFront: action.payload.contentFront,
            };
            cloneTexts.push(newText);
            return {
                texts: cloneTexts
            };
      case 'deleteText':
        cloneTexts.forEach((text) => {
          if (text.id === action.payload.id) {
            const index = cloneTexts.indexOf(text);
            cloneTexts.splice(index, 1)
          }
        });
        return {
          texts: cloneTexts
        };
      case 'editText':
        cloneTexts.map((text) => {
          if (text.id === action.payload.id) {
            text.title = action.payload.title;
            text.slug = action.payload.slug;
            text.description = action.payload.description;
            text.contentText = action.payload.contentText;
            text.contentFront = action.payload.contentFront;
            text.contentBack = action.payload.contentBack;
          }
          return null;
        });
        return {
          texts: cloneTexts
        };
        default:
            return state;
    }
}
