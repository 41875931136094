import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { HTTP } from "../../../axios";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-around'
  },
  title: {
    marginBottom: theme.spacing(5)
  },
  item: {
    marginBottom: theme.spacing(3)
  }
}));


const Settings = (props) => {

  document.title = 'SYNTHESIS | settings';

  const [mainSettings, mainSettingsSet] = useState();


  const seoSave = (ev) => {
    ev.preventDefault();

    const items = ev.target.querySelectorAll('[label]');
    const itemsValue = {};

    items.forEach((el) => {
      itemsValue[el.attributes.label.value] = el.children[0].value;
    });


    const wordsArr = itemsValue.random_words.split(',');

    wordsArr.forEach((el, i) => {
      wordsArr[i] = el.trim();
    });

    const filtered = wordsArr.filter((el) => {
      return el !== "";
    });


    itemsValue.random_words = [...filtered];


    function request() {
      HTTP({
        url: 'editMainSeo',
        method: 'POST',
        data: itemsValue
      })
        .then(() => {
          props.showActionDialog('success')
        })
        .catch(() => props.showActionDialog('error'))
    }
    request();
  };


  useEffect(() => {

    function request() {
      HTTP({
        url: 'getMainSeo',
        method: 'GET'
      })
        .then((data) => {
          mainSettingsSet(data.data[0]);
        })
        .catch((err) => console.log(err))
    }
    request();
// eslint-disable-next-line
  }, []);

  const classes = useStyles();

  if (!mainSettings) return null;

  return (
    <React.Fragment>
      <form id='mainSettings' onSubmit={(ev) => {
        seoSave(ev)
      }}>

        <Paper className={classes.paper}>
          <Grid item xs={10}>

            <Typography variant="h6" component="h4" className={classes.title}>
              Main site seo options
              <Typography variant="caption" display="block" gutterBottom>
                Title is what you'll see in your browser tab
              </Typography>
            </Typography>

            <FormControl fullWidth required className={classes.item}>
              <InputLabel>Site title</InputLabel>
              <Input label='title' defaultValue={mainSettings.title}/>
            </FormControl>

            <FormControl fullWidth required className={classes.item}>
              <InputLabel>Site description</InputLabel>
              <Input label='description' defaultValue={mainSettings.description}/>
            </FormControl>
          </Grid>
        </Paper>


        <Paper className={classes.paper}>
          <Grid item xs={10}>

            <Typography variant="h6" component="h4" className={classes.title}>
              Google analytics
              <Typography variant="caption" display="block" gutterBottom>
                Enter the id of google analytics
              </Typography>
            </Typography>

            <FormControl fullWidth className={classes.item}>
              <InputLabel>id</InputLabel>
              <Input label='googleId' defaultValue={mainSettings.googleId}/>
            </FormControl>

          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid item xs={10}>

            <Typography variant="h6" component="h4" className={classes.title}>
              OG Tags
              <Typography variant="caption" display="block" gutterBottom>
                The title of your page, content, object etc. as you would like for it to appear when displayed on
                Facebook and others
              </Typography>
            </Typography>

            <FormControl fullWidth className={classes.item}>
              <InputLabel>OG Title</InputLabel>
              <Input label='ogTitle' defaultValue={mainSettings.ogTitle}/>
            </FormControl>
            <FormControl fullWidth className={classes.item}>
              <InputLabel>OG Description</InputLabel>
              <Input label='ogDescription' defaultValue={mainSettings.ogDescription}/>
            </FormControl>
            <FormControl fullWidth className={classes.item}>
              <InputLabel>OG Image</InputLabel>
              <Input label='ogImage' defaultValue={mainSettings.ogImage}/>
            </FormControl>

          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid item xs={10}>

            <Typography variant="h6" component="h4" className={classes.title}>
              Random words
              <Typography variant="caption" display="block" gutterBottom>
                Enter the words will be displayed on the main animation
              </Typography>
            </Typography>

            <FormControl fullWidth required className={classes.item}>
              <InputLabel>Random words</InputLabel>
              <Input
                label='random_words'
                placeholder='Enter words separated by ,'
                defaultValue={mainSettings.random_words.join(',')}
              />
            </FormControl>

          </Grid>
        </Paper>

        <Button
          className={classes.item}
          variant="contained"
          color="primary"
          size='large'
          type='submit'
        >
          Save
        </Button>
      </form>
    </React.Fragment>
  )
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    showActionDialog: (type) => dispatch({
      type: 'showActionDialog',
      payload: type
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

