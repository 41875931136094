import React from 'react';
import {Close} from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {green} from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import {connect} from "react-redux";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";


const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  warning: ErrorIcon
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  warning: {
    background: 'linear-gradient(30deg, #7b1fa2 30%, #00acc1 90%)',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <Close className={classes.icon}/>
        </IconButton>,
      ]}
      {...other}
    />
  );
}


const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));


const ActionDialog = (props) => {

  const [mario, marioSet] = React.useState(false);

  const handleMarioOpen = () => {
    marioSet(true);
  };

  const handleMarioClose = () => {
    marioSet(false);
  };

  const classes = useStyles2();


  switch (props.actionDialog.type) {
    case 'success':
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.actionDialog.show}
          autoHideDuration={2000}
          onClose={props.hideActionDialog}
        >
          <MySnackbarContentWrapper
            onClose={props.hideActionDialog}
            variant="success"
            message="Everything is fine"
          />
        </Snackbar>
      );
    case 'error':
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.actionDialog.show}
          autoHideDuration={2000}
          onClose={props.hideActionDialog}
        >
          <MySnackbarContentWrapper
            onClose={props.hideActionDialog}
            variant="error"
            message="Something went wrong"
          />
        </Snackbar>
      );
    case 'mario':
      return (
        <React.Fragment>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={props.actionDialog.show}
            onClose={props.hideActionDialog}
          >
            <MySnackbarContentWrapper
              onClose={props.hideActionDialog}
              variant="warning"
              message={
                <React.Fragment>
                <span>DO U WANT PLAY IN &nbsp;
                  <strong
                    onClick={() => {
                      handleMarioOpen()
                    }}>
                    MARIO
                  </strong>
                  &nbsp;
                  DEAR?
                </span>
                  &nbsp;
                  <Button key="play" color="primary" size="small" onClick={props.hideActionDialog}>
                    PLAY
                  </Button>
                </React.Fragment>
              }
            />
          </Snackbar>


          <Dialog fullScreen open={mario} onClose={handleMarioClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleMarioClose} aria-label="close">
                  <Close/>
                </IconButton>
              </Toolbar>
            </AppBar>
            <iframe title='mario' src='https://g.vseigru.net/dasha1/280/super-mario-maker/' style={{height: '100%'}}/>
          </Dialog>

        </React.Fragment>
      );
    default:
      return null;
  }
};

function mapStateToProps(state) {
  return {
    actionDialog: state.actionDialog
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideActionDialog: () => dispatch({
      type: 'hideActionDialog'
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionDialog);
