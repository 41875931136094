const initionState = {
  login: false,
  token: null
};

export default function login(state = initionState, action) {
  switch (action.type) {
    case 'isLogin':
      return {
        login: action.payload.login,
        token: action.payload.token
      };
    default:
      return state;
  }
}
