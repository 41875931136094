import {combineReducers} from 'redux'
import texts from './texts'
import actionDialog from './actionDialog'
import login from './login'


export default combineReducers({
    texts,
    actionDialog,
    login
})
