import React, {useEffect} from 'react';
import history from "../../../history";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";

import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import {HTTP} from "../../../axios";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    padding: 0,
    height: '90vh'
  },
  paper: {
    width: 300,
    padding: theme.spacing(4),
    textAlign: 'left'
  },
  input: {
    marginTop: 20,
    marginBottom: 20
  },
  button: {
    marginTop: 40,
    width: '100%'
  },
  logOut: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around'
  }
}));


const Login = (props) => {

  document.title = 'SYNTHESIS | log in';

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token !== null) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, []);

  const isLogin = (ev) => {
    ev.preventDefault();

    const items = ev.target.querySelectorAll('[label]');

    const itemsValue = {};

    items.forEach((el) => {
      itemsValue[el.attributes.label.value] = el.children[0].value;
    });

    itemsValue.remember = document.getElementById('rememberCheck').checked;

    function request() {
      HTTP({
        url: 'isLogin',
        method: 'POST',
        data: itemsValue
      })
        .then((data) => {
          if (data.data.token !== false) {
            localStorage.setItem('token', data.data.token);
            props.isLogin({login: true, token: data.data.token});
            history.push('/')
          } else {
            items.forEach((el) => {
              el.classList.add('Mui-error');
            });
          }
        })
        .catch((err) => console.log(err))
    }
    request();

  };

  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <React.Fragment>
          <Typography gutterBottom variant="h5">
            Login
          </Typography>
          <Typography variant="body2" gutterBottom>
            Wanna do something? Log in first! <span role="img" aria-label="login">😎</span>
          </Typography>

          <form onSubmit={(ev) => {
            isLogin(ev)
          }}>
            <Box className={classes.input}>
              <FormControl fullWidth required>
                <InputLabel>Username</InputLabel>
                <Input label='username'/>
              </FormControl>
            </Box>
            <Box className={classes.input}>
              <FormControl fullWidth required>
                <InputLabel>Password</InputLabel>
                <Input label='password' type='password'/>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox id='rememberCheck' value="checkedA"/>
                  }
                  label="Remember me"
                />
              </FormControl>
            </Box>
            <Box>
              <Button className={classes.button} variant="contained" color="primary" size='large' type='submit'>
                Go
              </Button>
            </Box>
          </form>
        </React.Fragment>
      </Paper>
    </Container>
  )
};


function mapStateToProps(state) {
  return {
    login: state.login
  }
}

function mapDispatchToProps(dispatch) {
  return {
    isLogin: (loginData) => dispatch({
      type: 'isLogin',
      payload: loginData
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

